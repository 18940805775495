import * as React from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
// import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import "./NovoMenuDesk.css";
import { Collapse } from "@mui/material";
import imgAnimale from "../../../static/image61.svg";
import imgFarm from "../../../static/farm-rio-logo.png";
import imgSoma from "../../../static/Logo_Grupo_Soma_branco.svg";
import imgFabula from "../../../static/fabula-637456359398197697.png";
import imgFoxton from "../../../static/Foxton-Preta.png";
import imgMaria from "../../../static/mf-logo-400px.png";
import imgCris from "../../../static/logo-cris-barros-RGB.png";
import imgNV from "../../../static/Logo-nv-png-agosto.png";
import imgOFF from "../../../static/off-logo.svg";
import { useNavigate } from "react-router";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import Chip from "@mui/material/Chip";
import LogoutIcon from "@mui/icons-material/Logout";
import { UserContext } from "../../../contexts/UserContext";
import { useContext } from "react";
import { useState, useEffect } from "react";
import { getSessionStorage } from "../../../utils/sessionStorage";
import { colorirMenu } from "../../../utils/functions";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CameraOutlinedIcon from "@mui/icons-material/CameraOutlined";
import SendGTAGEvent from "../../../utils/sendGTAGEvent";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';


const ItemIcon = styled(ListItemIcon)(({ theme }) => ({ minWidth: 45, color: theme.palette.secondary.main, }));

export default function NovoMenuDesk({ title, body }) {
  const navigate = useNavigate();
  // const [openDropdownGest, setOpenDropdownGest] = React.useState(false);
  // const [openDropdown, setOpenDropdown] = React.useState(false);
  // const [openDropdownFotos, setOpenDropdownFotos] = React.useState(false);
  const [openDropdownLog, setOpenDropdownLog] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(getSessionStorage("user"));
  const [img, setImg] = useState(<img width="60" src={imgSoma} />);
  const { email, userLogout } = useContext(UserContext);


  const openNav = () => {
    setOpen(true);
    SendGTAGEvent("expandir_menu", "menu", "click", email);
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("main").style.marginLeft = "235px";
    document.getElementById("titleFarol").style.display = "initial";
    document.getElementById("titleFarol").style.marginRight = "10px";
    colorirMenu();
  };

  const closeNav = () => {
    setOpen(false);
    document.getElementById("mySidenav").style.width = "100px";
    document.getElementById("main").style.marginLeft = "85px";
    document.getElementById("titleFarol").style.display = "block";
    document.getElementById("titleFarol").style.marginRight = "0px";
    colorirMenu();
  };


  const handleDropdownLog = () => setOpenDropdownLog(!openDropdownLog);


  useEffect(() => {

    if (user.id_profile == 1) {
      document.getElementById('menu_admin').classList.remove('displayNone')
    }


    // if (user.id_usuario == '258' || user.id_usuario == '213' ) {
    if ([258, 213, 34].includes(user.id_usuario)) {
      document.getElementById('menu_atualizacoes').classList.remove('displayNone');
      document.getElementById('menu_simulacao').classList.remove('displayNone');
    }



    let imagem = null;
    switch (user.marcaSelecionada) {
      case "ANIMALE":
        imagem = imgAnimale;
        break;
      case "FARM":
        imagem = imgFarm;
        break;
      case "BYNV":
        imagem = imgNV;
        break;
      case "FOXTON":
        imagem = imgFoxton;
        break;
      case "FABULA":
        imagem = imgFabula;
        break;
      case "MARIA FILO":
        imagem = imgMaria;
        break;
      case "CRIS BARROS":
        imagem = imgCris;
        break;
      case "OFF":
        imagem = imgOFF;
        break;
      default:
        break;
    }
    setImg(<img width="60" src={user.marcasPermitidas.length > 1 ? imgSoma : imagem} />);
    colorirMenu();

  }, []);

  return (
    <>
      <div id="mySidenav" className="sidenav">
        <div className="img-marca">
          <p id="titleFarol">FAROL</p>
          {img}
        </div>

        <List sx={{ backgroundColor: "DBC7B8" }}>

          <div id='menu_simulacao' className="displayNone">
            {/* Simulação deflatores */}
            <ListItemButton onClick={() => { navigate("/simulacaoDeflatores"); }} className={open ? "hover-menu-aberto" : "displayNone"}>
              <ItemIcon className="icones"> <CalculateOutlinedIcon /> </ItemIcon>
              <ListItemText primary="Simulacao Deflatores" className={open ? "" : "displayNone"} />
            </ListItemButton>
            <ListItemButton className={open ? "icones-menu-aberto displaNone" : "icones-menu-fechado"} >
              <ItemIcon className="Simulacao Deflatores icones" onClick={openNav}  > <CalculateOutlinedIcon /> </ItemIcon>
            </ListItemButton>
          </div>

          {/* Performance */}
          <ListItemButton onClick={() => { navigate("/performance"); SendGTAGEvent("abrir_sub_menu_performance", "menu", "click", email); }} className={open ? "performance hover-menu-aberto" : "performance displayNone"}>
            <ItemIcon className="icones"> <EmojiEventsOutlinedIcon /> </ItemIcon>
            <ListItemText primary="Performance" className={open ? "" : "displayNone"} />
          </ListItemButton>
          <ListItemButton className={open ? "icones-menu-aberto displaNone" : "icones-menu-fechado"} >
            <ItemIcon className="performance icones" onClick={openNav}  > <EmojiEventsOutlinedIcon /> </ItemIcon>
          </ListItemButton>


          {/* Gestão */}
          <ListItemButton onClick={() => { navigate("/gestao"); SendGTAGEvent("abrir_sub_menu_resumo_diario", "menu", "click", email); }} className={open ? "gestao hover-menu-aberto" : "gestao displayNone"}>
            <ItemIcon className="icones"> <InsertChartOutlinedIcon /> </ItemIcon>
            <ListItemText primary="Gestão" className={open ? "" : "displayNone"} />
          </ListItemButton>
          <ListItemButton className={open ? "icones-menu-aberto displaNone" : "icones-menu-fechado"} >
            <ItemIcon className="gestao icones" onClick={openNav}  > <InsertChartOutlinedIcon /> </ItemIcon>
          </ListItemButton>


          {/* Estudio */}
          <ListItemButton onClick={() => navigate("/estudio")} className={open ? "estudio hover-menu-aberto" : "estudio displayNone"}>
            <ItemIcon className="icones"> <CameraOutlinedIcon /> </ItemIcon>
            <ListItemText primary="Estúdio" className={open ? "" : "displayNone"} />
          </ListItemButton>
          <ListItemButton className={open ? "icones-menu-aberto displaNone" : "icones-menu-fechado"} >
            <ItemIcon className="estudio icones" onClick={openNav}  > <CameraOutlinedIcon /> </ItemIcon>
          </ListItemButton>


          {/* Fotos */}
          <ListItemButton onClick={() => navigate("/fotos/inicio")} className={open ? "fotos hover-menu-aberto" : "fotos displayNone"}>
            <ItemIcon className="icones"> <PhotoLibraryOutlinedIcon /> </ItemIcon>
            <ListItemText primary="Fotos" className={open ? "" : "displayNone"} />
            <Chip label="Novo" color="primary" sx={{ opacity: open ? 1 : 0, ...(!open && { display: "none" }) }} />
          </ListItemButton>
          <ListItemButton className={open ? "icones-menu-aberto displaNone" : "icones-menu-fechado"} >
            <ItemIcon className="fotos icones" onClick={openNav}  > <PhotoLibraryOutlinedIcon /> </ItemIcon>
          </ListItemButton>


          <ListItemButton onClick={handleDropdownLog} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#DCC6B7" }, minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, ...(!open && { display: "none" }), "&.MuiButtonBase-root": { bgcolor: openDropdownLog ? "#DCC6B7" : "transparent", }, }} >
            <ItemIcon className="camera-box-chart2" onClick={openNav} sx={{ backgroundColor: "fff", ...(open && { display: "none" }) }} > <ArticleOutlinedIcon /> </ItemIcon>
            <ListItemText primary={"Log"} sx={{ opacity: open ? 1 : 0, ...(!open && { display: "none" }) }} />
            {openDropdownLog ? (<ExpandLess sx={{ opacity: open ? 1 : 0, ...(!open && { display: "none" }), }} />) : (<ExpandMore sx={{ opacity: open ? 1 : 0, ...(!open && { display: "none" }), }} />)}
          </ListItemButton>

          <ListItemButton onClick={handleDropdownLog} sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, ...(open && { display: "none" }), }} >
            <ItemIcon id="iconeLog" className="camera-box-chart" onClick={openNav} sx={{ backgroundColor: "fff", ...(open && { display: "none" }) }} >
              <ArticleOutlinedIcon />
            </ItemIcon>

            <ListItemText primary={"Log"} sx={{ opacity: open ? 1 : 0, ...(!open && { display: "none" }) }} />
            {openDropdownLog ? (<ExpandLess sx={{ opacity: open ? 1 : 0, ...(!open && { display: "none" }), }} />) : (<ExpandMore sx={{ opacity: open ? 1 : 0, ...(!open && { display: "none" }), }} />)}
          </ListItemButton>

          {/* ---------MENU EXPANDIDO---------- */}
          <div id='menu_admin' className="displayNone">
            <Collapse in={openDropdownLog} timeout="auto" unmountOnExit>
              <List component="div" style={{ paddingLeft: "20%" }} dense sx={{ ...(!open && { display: "none" }) }} >
                <ListItemButton key={"Acessos"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA", }, }} >
                  <ListItemText primary={"Acessos"} onClick={() => navigate("/log/acessos")} />
                </ListItemButton>
              </List>
            </Collapse>
          </div>

          <div id='menu_atualizacoes' className="displayNone">
            <Collapse in={openDropdownLog} timeout="auto" unmountOnExit>
              <List component="div" style={{ paddingLeft: "20%" }} dense sx={{ ...(!open && { display: "none" }) }} >
                <ListItemButton key={"Atualizações"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA", }, }} >
                  <ListItemText primary={"Atualizações"} onClick={() => navigate("/log/atualizacoes")} />
                </ListItemButton>
              </List>
            </Collapse>
          </div>

          <Collapse in={openDropdownLog} timeout="auto" unmountOnExit>
            <List component="div" style={{ paddingLeft: "20%" }} dense sx={{ ...(!open && { display: "none" }) }} >
              <ListItemButton key={"Visíveis"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA", }, }} >
                <ListItemText primary={"Visíveis"} onClick={() => navigate("/log/visiveis")} />
              </ListItemButton>
            </List>
          </Collapse>

          <Collapse in={openDropdownLog} timeout="auto" unmountOnExit>
            <List component="div" style={{ paddingLeft: "20%" }} dense sx={{ ...(!open && { display: "none" }) }} >
              <ListItemButton key={"Marketplace"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA", }, }} >
                <ListItemText primary={"Marketplace"} onClick={() => navigate("/log/marketplace")} />
              </ListItemButton>
            </List>
          </Collapse>


          <Collapse in={openDropdownLog} timeout="auto" unmountOnExit>
            <List component="div" style={{ paddingLeft: "20%" }} dense sx={{ ...(!open && { display: "none" }) }} >
              <ListItemButton key={"Resumo diário"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA", }, }} >
                <ListItemText primary={"Resumo diário"} onClick={() => navigate("/log/resumo-diario")} />
              </ListItemButton>
            </List>
          </Collapse>

          <Collapse in={openDropdownLog} timeout="auto" unmountOnExit>
            <List component="div" style={{ paddingLeft: "20%" }} dense sx={{ ...(!open && { display: "none" }) }} >
              <ListItemButton key={"Atraso ofertados"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA", }, }} >
                <ListItemText primary={"Atraso ofertados"} onClick={() => navigate("/log/atraso-ofertados")} />
              </ListItemButton>
            </List>
          </Collapse>

          <Collapse in={openDropdownLog} timeout="auto" unmountOnExit>
            <List component="div" style={{ paddingLeft: "20%" }} dense sx={{ ...(!open && { display: "none" }) }} >
              <ListItemButton key={"Scraping"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA", }, }} >
                <ListItemText primary={"Scraping"} onClick={() => navigate("/log/scraping")} />
              </ListItemButton>
            </List>
          </Collapse>


          {/*========================= LOG ========================= */}


          <div className="footerDrawer">
            <ListItemButton key={"Sair"} className="logout">
              <ItemIcon sx={{ minWidth: "fit-content !important", }} >
                <LogoutIcon />
              </ItemIcon>
              <ListItemText primary={"sair"} onClick={userLogout} />
            </ListItemButton>
          </div>
        </List>
      </div>

      <div id="main">
        <IconButton className="botao" color="inherit" onClick={!open ? openNav : closeNav} edge="start" >
          {!open ? (<ArrowForwardIosRoundedIcon sx={{ fontSize: "small !important" }} />) : (<ArrowBackIosRoundedIcon sx={{ fontSize: "small !important" }} />)}
        </IconButton>
        {title}
        {body}
      </div>
    </>
  );
}
