import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import Row from "./row";
import MenuFarolDeskMobile from "../../../components/Gestao/MenuFarol/MenuDeskMobile";
import { Skeleton } from "@mui/material";
import { Grid } from "@mui/material";
import { defaultRequest, exportarCsv, formatPrice } from "../../../utils/functions";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
// import ScrollSelect from "../../../components/Gestao/ScrollSelect/ScrollSelect";
import SelectColecoes from "../../../components/selectColecoes/index";
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';
import Papa from 'papaparse';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { ThemeProvider } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import { getSessionStorage } from "../../../utils/sessionStorage";

export default function SimulacaoDeflatores() {
  const [dadosOriginais, setDadosOriginais] = useState();
  const [dadosTable, setDadosTable] = useState([]);
  const [colecoes, setColecoes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deflatorMarca, setDeflatorMarca] = useState(0);
  // const [deflatorSeller, setDeflatorSeller] = useState(false);
  const [params, setParams] = useState({ filtro_colecao: false, filtro_marca: false });
  const marca = (getSessionStorage("user").marcaSelecionada).replace(/ /g, "");
  const [totalDeflatorSeller, setTotalDeflatorSeller] = useState(0);
  const [totalDeflatorMarca, setTotalDeflatorMarca] = useState(0);
  const [totalOferta, setTotalOferta] = useState(0);

  useEffect(() => {
    sessionStorage.removeItem(`deflatores_${marca}`)
    defaultRequest("/simulacaoDeflatores", params).then((value) => {
      setTotalOferta(value.total)
      setDadosOriginais(value.produtos);
      setDadosTable(value.produtos);
      setColecoes(value.colecoes);
      setLoading(false)
    })
  }, []);


  const aplicarDeflatorSeller = async (deflator) => {

    if (!deflator)
      return dadosTable;

    let foo = dadosTable
    setDadosTable([])
    let total = 0;
    let total2 = 0;

    for (let element of foo) {

      if (element.filial_estoque) {


        for (const item of element.filial_estoque) {
          const found = deflator[item.filial.toUpperCase()];

          if (found) {

            let valor = element.estoque_deflator ? element.estoque_deflator - parseInt(found) : parseInt(element.ESTOQUE) - parseInt(found);
            element.estoque_deflator = valor <= 0 ? 0 : valor;

            let valor2 = deflatorMarca ? parseInt(element.estoque_deflator) - parseInt(deflatorMarca) : element.ESTOQUE;
            element.estoque_deflator_marca = valor2 <= 0 ? 0 : valor2;

            if (valor == 0) {
              total += parseInt(element.ESTOQUE) * parseFloat(element.PRECO_ECOMMERCE);
              total2++
            }
          }
        }
      }
    }

    setTotalDeflatorSeller(total)
    setDadosTable(foo)
  }

  const handleChangeDeflatorMarca = async (e) => {
    const value = parseInt(e.target.value);
    let total = 0;
    let prod = dadosTable.map((item) => {

      if (value == 0) {
        delete item.estoque_deflator_marca;

      } else if (item.FLAG_DEFLATOR_MARCA) {
        let valor = item.estoque_deflator ? parseFloat(item.estoque_deflator) - value : parseFloat(item.ESTOQUE) - value
        item.estoque_deflator_marca = valor <= 0 ? 0 : valor;

        if (item.estoque_deflator_marca == 0) {
          total += parseInt(item.ESTOQUE) * parseFloat(item.PRECO_ECOMMERCE);
        }
      }
      return item;

    })

    setTotalDeflatorMarca(total);
    setDadosTable(prod)
    setDeflatorMarca(value)
  }

  const exportar = async () => {
    exportarCsv(dadosTable, "Simulacao_deflatores");
  };

  const importarCsv = async (e) => {
    const idToast = toast.loading("Aguarde um pouco...")
    let input = document.getElementById("selecao-arquivo");
    input.disabled = true;
    let arquivo = input.files[0];
    setLoading(true)


    // Usar a biblioteca para analisar o arquivo
    await Papa.parse(arquivo, {
      header: true, // Define se a primeira linha do CSV é um cabeçalho
      skipEmptyLines: true,
      delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
      complete: (result) => { // Acessar complete após realizar a leitura do CSV

        let deflatores = {};
        result.data.forEach(el => { deflatores[el.seller.toUpperCase().trim()] = el.valor });
        toast.update(idToast, { isLoading: false, autoClose: 1 });

        // setDeflatorSeller(deflatores)
        aplicarDeflatorSeller(deflatores);


      }, error: (error) => { // Acessar error se houver erro na leitura do CSV
        toast.update(idToast, { render: `Erro ao analisar o CSV: ${error.message}`, type: "error", isLoading: false, autoClose: 10000 });
      },
    });

    input.disabled = false;
    setLoading(false)
  };


  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />

      <MenuFarolDeskMobile
        title={<HeaderDesk atualizado={"all the time"} setBusca={false} setLoading={setLoading} tipoTela={"LOG"} title={"SIMULAÇÃO DEFLATORES"} enderecoVoltar={"/simulacaoDeflatores"} />}
        body={
          <div className="card-total">
            <div className="divider-pacote" />

            <div className="box-geral resumo-diario">
              <SelectColecoes colecoes={colecoes} dadosOriginais={dadosOriginais} setDadosTable={setDadosTable} setTotalOferta={setTotalOferta} setLoading={setLoading} />

              <div className="box-valores-deflatores">
                <div className="m-r-20">
                  <Typography component="h2" className="title-box-select">Total ecommerce </Typography>
                  <spam className="box-upload-datas-2">
                    <div className="texto-total-deflatores"> {formatPrice(totalOferta)} </div>
                  </spam>
                </div>
                <div className="m-r-20">
                  <Typography component="h2" className="title-box-select">Deflator de marca </Typography>
                  <spam className="box-upload-datas-2">
                    <div className="texto-total-deflatores"> {formatPrice(totalDeflatorMarca)} </div>
                  </spam>
                </div>
                <div className="m-r-20">
                  <Typography component="h2" className="title-box-select">Deflator de seller </Typography>
                  <spam className="box-upload-datas-2">
                    <div className="texto-total-deflatores"> {formatPrice(totalDeflatorSeller)} </div>
                  </spam>
                </div>
              </div>

              <div className="m-r-10">
                <Typography component="h2" className="title-box-select"> Deflator de marca </Typography>
                <FormControl variant="outlined" className="select select-deflator-marca select-scroll-lateral"  >
                  <ThemeProvider >
                    <Select displayEmpty onChange={handleChangeDeflatorMarca} value={deflatorMarca}>
                      <MenuItem value="0"> QTD. 0 </MenuItem>
                      <MenuItem value="1"> QTD. 1 </MenuItem>
                      <MenuItem value="2"> QTD. 2 </MenuItem>
                      <MenuItem value="3"> QTD. 3 </MenuItem>
                      <MenuItem value="4"> QTD. 4 </MenuItem>
                      <MenuItem value="5"> QTD. 5 </MenuItem>
                    </Select>
                  </ThemeProvider>
                </FormControl>
              </div>

              <div>
                <label className="label-input-file input-upload" for='selecao-arquivo'>
                  <input onChange={importarCsv} id='selecao-arquivo' type='file' draggable="true" accept=".csv" />
                  <spam className="box-upload-datas-2">
                    <DriveFolderUploadRoundedIcon className="icone-upload-deflatores" />
                  </spam>
                </label>
              </div>

              <button className="btn-exportar-log btn-exportar-deflatores" onClick={exportar}> Exportar </button>

            </div>

            {!loading ? (
              <>
                <Grid container>
                  <table className="">
                    <thead>
                      <tr className="header-tabela">
                        <th className="th">Refid</th>
                        <th className="th">Coleção</th>
                        <th className="th">Receita</th>
                        <th className="th">Estoque</th>
                        <th className="th">Receita deflator</th>
                        <th className="th">Estoque deflator</th>
                        <th className="th">Receita deflator marca</th>
                        <th className="th">Estoque deflator marca</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dadosTable.map((iten) => { return <Row row={iten} />; })}
                    </tbody>
                  </table>

                </Grid>
              </>
            ) : (<Skeleton className="skeleton-format" variant="rectangular" height={200} />)
            }
          </div >
        }
      />
    </>
  );
}
