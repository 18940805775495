import { useState, useEffect, useContext } from "react";
import * as React from "react";
import "./produtosMarketplace.css";
import { Typography, Grid } from "@material-ui/core";
import Container from "@mui/material/Container";
import Grafico from "../../../components/Gestao/Grafico/index";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { defaultRequest, formatPrice, handleClickTab, selTodasReferencias, toastRetorno, atualizarHtmlTabela, objToCSV } from "../../../utils/functions";
import { useNavigate } from "react-router";
import { Skeleton } from "@mui/material";
import SearchButton from "../../../components/Gestao/SearchButton/Search";
import UserInfo from "../../../components/Gestao/UserInfo/UserInfo";
import MenuFarolDeskMobile from "../../../components/Gestao/MenuFarol/MenuDeskMobile";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
import CardSearch from "../../../components/Gestao/CardSearch/CardSearch";
import TabelaProdutosMarketplace from "../../../components/Gestao/TabelaProdutosMarketplace/TabelaProdutosMarketplace";
import CardOfertadosNaoOfertados from "../../../components/Gestao/CardOfertadosNaoOfertados/CardOfertadosNaoOfertados";
import ScrollSelect from "../../../components/Gestao/ScrollSelect/ScrollSelect";
import { getSessionStorage, removeSessionStorage } from "../../../utils/sessionStorage";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../../../contexts/UserContext";
import SendGTAGEvent from "../../../utils/sendGTAGEvent";
import IosShareTwoToneIcon from "@mui/icons-material/IosShareTwoTone";
import InfoMenuMobile from "../../../components/InfoMenuMobile/InfoMenuMobile";

export default function produtosMarketplace() {

  const [params, setParams] = useState({ filtro_lancados: false, filtro_colecao: false });
  const [dados, setDados] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [busca, setBusca] = useState(false);
  const [valueTab, setValueTab] = useState(1);
  const navigate = useNavigate();
  const route = "/produtosMarketplace";
  const { email } = useContext(UserContext);
  const [user, setUser] = useState(getSessionStorage("user"));
  const [marca, setMarca] = useState(user ? (user.marcaSelecionada).replace(/ /g, "") : false);


  // PARA APAGAR REFERENCIAS SELECIONADAS NO MODAL DE NÃO VISIVEL QUANDO O MESMO FOR ABERTO
  removeSessionStorage(`refSelecionadasMarketplace_${marca}`)

  useEffect(() => {
    defaultRequest(route, params, setLoading).then(function (response) {
      // console.log(response)
      setDados(response)
    }).catch((error) => {
      console.log(error.message);
      return;
    });

    window.onscroll = function () { scrollFunction() };
  }, []);


  const scrollFunction = () => {

    const el = document.getElementById("id_scroll_btn_aprovar")
    let elemento = document.getElementById("btn_aprovar_marketplace");

    if (el && elemento) {
      const posicoes = el.getBoundingClientRect();
      if (posicoes.top >= 300) {
        elemento.classList.add('esconder-btn')
      } else {
        elemento.classList.remove('esconder-btn')
      }
    }
  }

  const aprovar = async () => {

    const referencias = JSON.parse(sessionStorage.getItem(`refSelecionadasMarketplace_${marca}`)) || [];
    removeSessionStorage(`refSelecionadasMarketplace_${marca}`)

    if (referencias.length == 0)
      return

    SendGTAGEvent("button_aprovar_referencias_pendendtes_marketplace", "marketplace", "click", email);

    const idToast = toast.loading("Aguarde um pouco...")

    await defaultRequest('/gestao/integracao/produtosMarketplace', { referencias: referencias, usuario: user.email }).then(function (response) {

      toast.dismiss(idToast);
      atualizarHtmlTabela(response.sucesso, "Pendentes de aprovação")
      if ((referencias.length == dados.pendentes.length) && (response.error.length == 0)) {
        toast.success(`Referências aprovadas com sucesso!`);
        document.getElementById("cardOfferPendentes").classList.remove('displayNone');

        const btn_todas = document.getElementById(`checkboxTodasReferencias`);
        btn_todas.checked ? btn_todas.click() : false;
      } else {
        toastRetorno(response);
      }

    }).catch((error) => {
      console.log(error.message)
      toast.update(idToast, { render: 'Houve um erro interno!', type: "error", isLoading: false, autoClose: 3000 });
    });

    selTodasReferencias(false, 'checkboxMarketplace', 'refSelecionadasMarketplace', 'btn_aprovar_marketplace')
  };

  const exportCsv = async () => {

    const idToast = toast.loading("Aguarde um pouco...")
    let tudo = dados.pendentes.concat(dados.rejeitados, dados.nao_encontrados);
    let ret = [];
    tudo.map((item) => {
      let iten = {};
      iten.referencias = item.REF;
      iten.status = item.status ? item.status : "Não encontrado na vtex";
      iten.colecao = item.COLECAO;
      iten.valor_em_estoque = item.valor_em_estoque;
      iten.qtd_de_skus_em_estoque = item.qtd_skus;
      ret.push(iten);
    })

    await objToCSV(ret);
    toast.dismiss(idToast);
    toast.success(`Arquivo exportado com sucesso!`);

  };

  return (
    <>
      <MenuFarolDeskMobile
        title={<HeaderDesk atualizado={dados.atualizado} setBusca={setBusca} tipoBusca={"MARKETPLACE"} setLoading={setLoading} title={"Estoque não ofertado marketplace"} enderecoVoltar={"/gestao"} />}
        body={
          <Container maxWidth="sm" className="container">
            <InfoMenuMobile title={"Estoque não ofertado marketplace"} setBusca={setBusca} tipoBusca={"MARKETPLACE"} setLoading={setLoading} atualizado={dados.atualizado} tipoTela={true} enderecoVoltar={"/gestao"} />
            <div className="divider-top" />

            {!busca ? (<>

              <div className="box-top">
                <ScrollSelect colecoes={dados.colecoes} params={params} setParams={setParams} setDados={setDados} setLoading={setLoading} tela={"marketplace"} route={route} pacotes={dados.pacotes} />
              </div>

              <div className="tab-box-total">
                <div className="box">
                  <Grid container >
                    <Grid xs={12} md={6} container>
                      <Grid container alignItems="center" className="box-individual box-individual-not"  >
                        {isLoading ? (<Skeleton className="skeleton-format" variant="rectangular" height={250} />) : (<>
                          <div style={{ display: "flex", flexDirection: "column", }} >
                            <Typography className="titulo-grafico2"> Total de Produtos marketplace </Typography>
                            <div className="graph-sku">
                              <Grafico part1={dados.nao_encontrados_porcentagem} part2={dados.pendentes_porcentagem} tipoTela={2} />

                              <div className="div-sku">

                                <div className="white-space-2">
                                  <Typography className="sub-titulo-1"> Qtd. de Referências</Typography>
                                  <Typography className="sub-titulo-2"> {dados.qtd_refs} </Typography>
                                </div>

                                <div className="white-space-2">
                                  <Typography className="sub-titulo-1"> Qtd. de SKU</Typography>
                                  <Typography className="sub-titulo-2"> {dados.qtd_skus} </Typography>
                                </div>

                                <div className="white-space-2">
                                  <Typography className="sub-titulo-1"> Receita </Typography>
                                  <Typography className="sub-titulo-2"> {formatPrice(dados.nao_encontrados_receita + dados.pendentes_receita)}  </Typography>
                                </div>
                              </div>

                            </div>
                          </div>
                        </>
                        )}
                      </Grid>
                    </Grid>

                    <Grid xs={12} md={6} container>
                      <CardOfertadosNaoOfertados porcentagem={dados.nao_encontrados_porcentagem} qtd={dados.qtd_nao_encontrados_skus} qtdRefs={dados.qtd_nao_encontrados_refs} valor={dados.nao_encontrados_receita} cor={"orange"} texto={"Não encontrados"} isLoading={isLoading} />
                      <div className="divider-2" />
                      <CardOfertadosNaoOfertados porcentagem={dados.pendentes_porcentagem} qtd={dados.qtd_pendentes_skus} qtdRefs={dados.qtd_pendentes_refs} valor={dados.pendentes_receita} cor={"purple"} texto={"Pendentes de aprovação"} isLoading={isLoading} />
                    </Grid>
                  </Grid>
                </div>

                <Grid container className="box-tab-pai" >
                  {!dados ? (<Skeleton className="skeleton-format skeleton-nao-ofertados" variant="rectangular" />) : (<>
                    <div className="tab" id="id_scroll_btn_aprovar">
                      <div className="box-tab-btn">
                        <button name="nao_encontrados" className="tablinks active" onClick={(e) => handleClickTab(e, setValueTab)} >
                          Não Encontrados ({dados.nao_encontrados.length})
                        </button>
                        <button id="total_itens" value={dados.pendentes.length} name="pendentes" className="tablinks" onClick={(e) => handleClickTab(e, setValueTab)} >
                          Pendentes de aprovação ({dados.pendentes.length})
                        </button>
                        <div style={{ "width": "100%" }}>
                          <button className="btn_exportar_marketplace btn-aprovar" onClick={exportCsv}>Exportar <IosShareTwoToneIcon /></button>
                          {valueTab == 2 &&
                            <button className="btn-aprovar btn_aprovar_marketplace" id="btn_aprovar_marketplace" onClick={aprovar}>Aprovar</button>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="tab-box">
                      <div id="nao_encontrados" className="tabcontent" style={{ display: "flex" }} >
                        <Grid container spacing={1}>
                          <TabelaProdutosMarketplace rows={dados.nao_encontrados} />
                        </Grid>
                      </div>

                      <div id="pendentes" className="tabcontent" style={{ display: "none" }} >
                        <Grid container spacing={1} >
                          <TabelaProdutosMarketplace rows={dados.pendentes} tipo_tabela={true} click={aprovar} />
                        </Grid>
                      </div>

                    </div>
                  </>)}
                </Grid>
              </div>
            </>) : (<>
              <div className="white-space-2" />
              <Grid container spacing={1} >
                {busca.map((iten) => { return (<CardSearch iten={iten} isLoading={!dados} motivo={"Marketplace"} />); })}
              </Grid>
            </>
            )}
          </Container >
        }
      />

    </>
  );
}
