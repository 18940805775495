import React from "react";
import { useState, useEffect } from "react";
import MenuFarolDeskMobile from "../../components/Gestao/MenuFarol/MenuDeskMobile";
import { Grid, Pagination } from "@mui/material";
import { defaultRequest, exportarCsv } from "../../utils/functions";
import "react-datepicker/dist/react-datepicker.css";
import "./acessos.css";
import Row from "./rowResumoDiario";
import HeaderDesk from "../../components/Gestao/HeaderDesk/HeaderDesk";
import ScrollSelect from "../../components/Gestao/ScrollSelect/ScrollSelect";
import { Skeleton } from "@mui/material";

export default function ResumoDiario() {
  const [dadosOriginais, setDadosOriginais] = useState([]);
  const [dadosTable, setDadosTable] = useState([]);
  const [colecoes, setColecoes] = useState([]);
  const [params, setParams] = useState({ filtro_colecao: false, filtro_marca: false });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  let pageCount = Math.floor(dadosTable.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(async () => {
    let foo = await defaultRequest("/log", { tipo: "RESUMO_DIARIO" });
    let foo2 = await defaultRequest("/logDiarioGestaoOfertados", {});
    foo2 = foo2.concat(foo);
    setDadosOriginais(foo2);
    setDadosTable(foo2);

    let array = [];
    foo2.map((dado) => array.push(dado.COLECAO));
    array = [...new Set(array)];
    setColecoes(array);
  }, []);

  const exportar = async () => {
    exportarCsv(dadosTable, "LOG_RESUMO_DIARIO_FAROL");
  };

  return (
    <>
      <MenuFarolDeskMobile
        title={
          <HeaderDesk
            atualizado={"all the time"}
            setBusca={false}
            setLoading={false}
            tipoTela={"LOG"}
            title={"LOG RESUMO DIÁRIO"}
            enderecoVoltar={"/gestao"}
          />
        }
        body={
          <div className="card-total">
            <div className="divider-pacote" />

            <div className="box-geral resumo-diario">
              <ScrollSelect
                colecoes={colecoes}
                params={params}
                setParams={setParams}
                dadosOriginais={dadosOriginais}
                setDadosTable={setDadosTable}
                tela={"log resumo diário"}
              />
              {/* <h2 className="titulo-acesso">LOG RESUMO DIÁRIO</h2> */}
              <button className="btn-exportar-log" onClick={exportar}>
                Exportar
              </button>
            </div>

            {dadosTable.length > 0 ? (
              <>
                <Grid container className="">
                  <table>
                    <thead>
                      <tr className="header-tabela">
                        <th className="th">Coleção</th>
                        <th className="th">Data</th>
                        <th className="th">Receita</th>
                        <th className="th">Receita Ofertados</th>
                        <th className="th">%</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dadosTable.length > 0
                        ? dadosTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((iten) => {
                          return <Row row={iten} />;
                        })
                        : ""}
                    </tbody>
                  </table>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "2% 0px",
                  }}
                >
                  <Pagination count={pageCount} rowsPerPage={rowsPerPage} page={page} onChange={handleChangePage} />{" "}
                </div>
              </>
            ) : (<Skeleton className="skeleton-format" variant="rectangular" height={200} />)
            }
          </div>
        }
      />
    </>
  );
}
